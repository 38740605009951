/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */
/* eslint no-unused-vars: ["error", { allow: ["log","warn", "error"] }] */
import { db } from '@/plugins/firebase';
import { de_umlaut } from '../../plugins/helpers';

const state = {
  json: '',
  categories: [],
  word_categories: [],
  listenCategories: false,
};
const getters = {
  getList: (state) => state.categories,
  getWordCategoriesList: (state) => state.word_categories,
};
const actions = {
  async listen({ commit, state }) {
    if (state.listenCategories === true) {
      return;
    }
    const docRef = db.collection('config').doc('categories');
    docRef.onSnapshot((doc) => {
      const categoriesData = doc.data();
      const categories = [];

      // eslint-disable-next-line no-unused-vars
      for (const [id, category] of Object.entries(categoriesData.list)) {
        category['id'] = id;
        categories.push(category);
      }

      categories.sort(function (a, b) {
        const aw = de_umlaut(a.name);
        const bw = de_umlaut(b.name);

        if (aw < bw) {
          return -1;
        }
        if (aw > bw) {
          return 1;
        }
        return 0;
      });
      commit('setCategories', categories);
    });
  },

  async fetchList({ commit, getters }) {
    if (getters.getList.length > 0) {
      return;
    }
    const categories = [];
    await db
      .collection('categories')

      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return;
        }

        snapshot.forEach((doc) => {
          const category = doc.data();
          categories.push(category);
        });
        commit('setCategories', categories);
      })
      .catch((err) => {
        this.error = err;
        // console.log('Error getting documents', err);
      });
  },
  async createWordCategory({ commit, state }, payload) {
    const { category } = payload;
    if (category['name'] === '') {
      return;
    }
    category['sort_name'] = de_umlaut(category['name']);

    category['show_in_app'] = category['show_in_app'] === true ? true : false;
    await db.collection('word_categories').add(category);
    const updated_categories = state.word_categories.slice();
    updated_categories.push(category);
    commit('setWordCategories', updated_categories);
  },
  async deleteWordCategory({ commit, state }, payload) {
    const { category } = payload;
    await db.collection('word_categories').doc(category.id).delete();

    const updated_categories = state.word_categories
      .slice()
      .filter((cat) => cat.id !== category.id);

    commit('setWordCategories', updated_categories);
  },
  // eslint-disable-next-line no-unused-vars
  async updateWordCategory({ commit, state }, payload) {
    const { category } = payload;
    await db
      .collection('word_categories')
      .doc(category.id)
      .set(category, { merge: true });

    let updated_categories = state.word_categories
      .slice()
      .filter((cat) => cat.id !== category.id);

    updated_categories.push(category);
    commit('setWordCategories', updated_categories);
  },
  async fetchWordCategoriesList({ commit, getters }, payload) {
    let forced = false;
    if (payload !== undefined && payload.forced === true) {
      forced = true;
    }
    if (
      1 == 1 ||
      (getters.getWordCategoriesList.length > 0 && forced === false)
    ) {
      return;
    }

    const word_categories = [];
    await db
      .collection('word_categories')

      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return;
        }

        snapshot.forEach((doc) => {
          const category = doc.data();
          category['id'] = doc.id;
          word_categories.push(category);
        });
        commit('setWordCategories', word_categories);
      })
      .catch((err) => {
        this.error = err;
        // console.log('Error getting documents', err);
      });
  },
};
const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },
  setWordCategories(state, word_categories) {
    state.word_categories = word_categories;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
