const defaultWord = {
  id: "",
  word: "",
  article: "",
  audios: [],
  videos: [],
  symbol: {},
  categories: [],
  dictionaries: [],
};

export default defaultWord;
