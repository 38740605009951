<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app>
      <v-list nav dense>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item :to="{ name: 'home' }">
            <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item to="/words/">
            <v-list-item-icon
              ><v-icon>mdi-tooltip-image</v-icon></v-list-item-icon
            >
            <v-list-item-title>Wörter verwalten</v-list-item-title>
          </v-list-item>
          <v-list-item to="/categories/">
            <v-list-item-icon><v-icon>mdi-database</v-icon></v-list-item-icon>
            <v-list-item-title>Kategorien verwalten</v-list-item-title>
          </v-list-item>
          <v-list-item to="/dictionaries/">
            <v-list-item-icon><v-icon>mdi-notebook</v-icon></v-list-item-icon>
            <v-list-item-title>Wörterbücher verwalten</v-list-item-title>
          </v-list-item>
          <v-list-item to="/releases/">
            <v-list-item-icon><v-icon>mdi-upload</v-icon></v-list-item-icon>
            <v-list-item-title>Releases verwalten</v-list-item-title>
          </v-list-item>
          <v-list-item to="/campaigns/">
            <v-list-item-icon><v-icon>mdi-ticket</v-icon></v-list-item-icon>
            <v-list-item-title>Coupon Kampagnen</v-list-item-title>
          </v-list-item>

          <v-list-item href="https://old-admin.eis-app.de/">
            <v-list-item-icon
              ><v-icon>mdi-link-box-variant</v-icon></v-list-item-icon
            >
            <v-list-item-title
              >Wörter-Administration von App-Version 1
              (aktuell)</v-list-item-title
            >
          </v-list-item>

          <v-list-item v-if="debug" to="/debug/">
            <v-list-item-icon><v-icon>mdi-bug</v-icon></v-list-item-icon>
            <v-list-item-title> Debug </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="toolbar-title"
        ><router-link to="/"
          >EiS Admin | {{ title }}</router-link
        ></v-toolbar-title
      >

      <v-spacer></v-spacer>
      <!-- <div class="flex-grow-1 flex-shrink-0 mt-4">
        <SearchUserInput v-if="isAuthenticated === true" />
      </div> -->
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon v-if="!debug" @click="toggleDebug">mdi-bug</v-icon>
        <v-icon v-if="debug" @click="toggleDebug">mdi-bug-check</v-icon>
      </v-btn>
      <v-btn icon v-if="isAuthenticated === true" @click="logout()">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<style>
.v-toolbar__title.toolbar-title a {
  color: white;
  text-decoration: none;
}

div.v-sheet {
  padding: 12px;
}
</style>

<script>
// import SearchUserInput from "./components/SearchUserInput";
import { mapGetters } from "vuex";

export default {
  name: "App",

  components: {
    // SearchUserInput,
  },
  created: function () {
    this.$store.dispatch("categories/listen");
    this.$store.dispatch("dictionaries/listen");
    this.$store.dispatch("releases/listen");
    // this.$store.dispatch("words/listen");
    this.$store.dispatch("words/getWords");
    // this.$store.dispatch("auth/checkAuthentication");
    // this.$store.dispatch("words/listenWords");
  },
  data: () => ({
    drawer: false,
  }),
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      title: "navigation/title",
      debug: "navigation/debug",
    }),
  },
  methods: {
    toggleDebug() {
      this.$store.dispatch("navigation/setDebug", { debug: !this.debug });
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>
