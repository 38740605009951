/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */
/* eslint no-unused-vars: ["error", { allow: ["log","warn", "error"] }] */
import { db } from '@/plugins/firebase';

const state = {
  error: '',
  release: {},
  history: [],
};
const getters = {
  get: (state) => state.release,
  getHistory: (state) => state.history,
  getError: (state) => state.error,
};
const actions = {
  async listen({ commit, getters }) {
    if (getters.getHistory.length > 0) {
      return;
    }

    const docRef = await db.collection('config').doc('configFile');
    docRef.onSnapshot((doc) => {
      const data = doc.data();

      const history = data['history'];
      history.sort((a, b) => new Date(b.updated) - new Date(a.updated));

      commit('setCurrent', data['current']);
      commit('setHistory', data['history']);
    });
  },

  async create({ commit }) {
    commit('setError', 'create not implemented');
  },
};
const mutations = {
  setError(state, error) {
    state.error = error;
  },
  setCurrent(state, release) {
    state.release = release;
  },
  setHistory(state, history) {
    state.history = history;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
