import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';

/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */

Vue.use(Router);

let router = new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      meta: { auth: false, title: 'login' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "login" */ '@/pages/Login.vue'),
    },
    {
      path: '/',
      name: 'home',
      meta: { auth: true, title: 'Home' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "home" */ '@/pages/Home.vue'),
    },
    {
      path: '/user/:id',
      name: 'user-detail',
      meta: { auth: true, title: 'Nutzer' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "userdetail" */ '@/pages/UserDetail.vue'),
    },
    {
      path: '/words',
      name: 'words',
      meta: { auth: true, title: 'Wörter' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "words" */ '@/pages/Words.vue'),
    },
    {
      path: '/words/:id',
      name: 'words-filtered',
      meta: { auth: true, title: 'Wörter' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "words" */ '@/pages/Words.vue'),
    },
    {
      path: '/word/:id',
      name: 'word-detail',
      meta: { auth: true, title: 'Wort' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "userdetail" */ '@/pages/Word.vue'),
    },
    {
      path: '/word/',
      name: 'word-detail-create',
      meta: { auth: true, title: 'Neues Wort' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "userdetail" */ '@/pages/Word.vue'),
    },
    {
      path: '/categories',
      name: 'categories',
      meta: { auth: true, title: 'Kategorien' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "categories" */ '@/pages/Categories.vue'),
    },
    {
      path: '/releases',
      name: 'releases',
      meta: { auth: true, title: 'Releases' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "categories" */ '@/pages/Releases.vue'),
    },
    {
      path: '/dictionaries',
      name: 'dictionaries',
      meta: { auth: true, title: 'Wörterbücher' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "dictionaries" */ '@/pages/Dictionaries.vue'
        ),
    },
    {
      path: '/campaigns',
      name: 'campaigns',
      meta: { auth: true, title: 'Kampagnen' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "dictionaries" */ '@/pages/Campaigns.vue'),
    },
    {
      path: '/campaign/:id',
      name: 'campaign-detail',
      meta: { auth: true, title: 'Wort' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "userdetail" */ '@/pages/Campaign.vue'),
    },
    {
      path: '/debug',
      name: 'debug',
      meta: { auth: true, title: 'debug' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "debug" */ '@/pages/Debug.vue'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth === true) {
    if (!store.getters['auth/isAuthenticated']) {
      next({ name: 'login' });
      return;
    }
  }

  if (to.name === 'login') {
    if (store.getters['auth/isAuthenticated']) {
      next({ name: 'home' });
      return;
    }
  }
  next();
});

const DEFAULT_TITLE = '';
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    let title = to.meta.title || DEFAULT_TITLE;
    store.dispatch('navigation/setTitle', { title });
  });
});
// this.$store.dispatch("word/createWord");
export default router;
