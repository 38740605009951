/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */
// import { db } from "@/plugins/firebase";
import plans from '@/data/plans';
import { db, Timestamp, FieldValue } from '@/plugins/firebase';

const state = {
  plans: plans,
  saving: false,
};

const getters = {
  plans: (state) => state.plans,
};

const actions = {
  logPlans({ commit, state }) {
    // es
    commit('resetPlans');
    console.log(state.plans);
  },
  async addCustomSubscription({ commit, dispatch }, { subscription, uid }) {
    commit('saving', true);
    let current_period_end = Timestamp.fromDate(
      subscription.current_period_end.toDate()
    );
    let subscriptionData = {
      id: subscription.id,
      payment_provider: subscription.payment_provider,
      cancel_at_period_end: subscription.cancel_at_period_end,
      current_period_end: current_period_end,
      current_period_start: Timestamp.now(),
      device_count: subscription.device_count,
      price: subscription.price * 100,
    };

    const docRef = db.collection('users').doc(uid);
    await docRef.set(
      { current_subscription: subscriptionData },
      { merge: true }
    );
    await dispatch('user/fetch', { uid: uid }, { root: true });
    commit('saving', false);
  },
  async cancelCustomSubscription({ commit, dispatch }, { subscription, uid }) {
    commit('saving', true);
    const now = new Date();
    const new_end = Timestamp.fromDate(now);
    subscription.canceled = new_end;
    const update = {
      current_subscription: FieldValue.delete(),
      subscription_history: FieldValue.arrayUnion(subscription),
    };
    console.log({ now, new_end, uid, update });

    const docRef = db.collection('users').doc(uid);
    await docRef.set(update, { merge: true });
    await dispatch('user/fetch', { uid: uid }, { root: true });
    commit('saving', false);
  },
};

const mutations = {
  resetPlans(state) {
    state.plans = plans;
  },
  saving(state, saving) {
    state.saving = saving;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
