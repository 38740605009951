import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';
import categories from './modules/categories';
import customSubscription from './modules/customSubscription';
import dictionaries from './modules/dictionaries';
import navigation from './modules/navigation';
import search from './modules/search';
import user from './modules/user';
import word from './modules/word';
import words from './modules/words';
import debug from './modules/debug';
import releases from './modules/releases';
import campaigns from './modules/campaigns';

Vue.use(Vuex);

const debugMode = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    auth,
    dictionaries,
    categories,
    user,
    search,
    customSubscription,
    navigation,
    word,
    words,
    debug,
    releases,
    campaigns,
  },
  strict: debugMode,
  //   plugins: debug ? [createLogger()] : []
  plugins: [
    createPersistedState({
      key: 'eis-admin-v2',
      paths: ['auth', 'search', 'navigation.debug', 'campaigns.campaign'],
    }),
  ],
});
