import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
// import vClickOutside from "./plugins/v-click-outside";
import router from './plugins/router';
import store from './store';
import './plugins/firebase-storage-src';
import moment from 'moment';

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm');
  }
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,

  //   vClickOutside,
  render: (h) => h(App),
}).$mount('#app');
