/* eslint no-unused-vars: ["error", { allow: ["log","warn", "error"] }] */
/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */

import { db, functions } from '@/plugins/firebase';
import { de_umlaut } from '../../plugins/helpers';

const state = {
  json: '',
  words: [],
};
const getters = {
  json: (state) => state.json,
  getList: (state) => state.words,
  //   configWords: (state) => state.configWords,
};
const actions = {
  async listen({ commit, state }) {
    if (state.words.length > 0) {
      return;
    }
    const docRef = db.collection('config').doc('words');
    docRef.onSnapshot((doc) => {
      const wordsData = doc.data();
      const words = [];
      console.log({ wordsData });
      // eslint-disable-next-line no-unused-vars
      for (const [id, word] of Object.entries(wordsData.list)) {
        words.push(word);
      }
      words.sort(function (a, b) {
        const aw = de_umlaut(a.word);
        const bw = de_umlaut(b.word);

        if (aw < bw) {
          return -1;
        }
        if (aw > bw) {
          return 1;
        }
        return 0;
      });
      console.log({ words });
      commit('setWords', words);
    });
  },
  // eslint-disable-next-line no-unused-vars
  async getWords({ commit, state }) {
    if (state.words.length > 0) {
      return;
    }
    const words = [];
    const wordsRef = db.collection('words');
    const wordsSnapshot = await wordsRef.get();

    console.log({ wordsSnapshot });

    wordsSnapshot.forEach((doc) => {
      const word = doc.data();
      words.push({ ...word });
    });
    console.log({ words });

    words.sort(function (a, b) {
      const aw = de_umlaut(a.word);
      const bw = de_umlaut(b.word);

      if (aw < bw) {
        return -1;
      }
      if (aw > bw) {
        return 1;
      }
      return 0;
    });
    console.log({ words });
    commit('setWords', words);
  },
  // eslint-disable-next-line no-unused-vars
  async listenWords({ commit, state }) {
    if (state.words.length > 0) {
      return;
    }
    const words = [];
    const docRef = db.collection('words');
    docRef.onSnapshot((querySnapshot) => {
      const docs = querySnapshot.docs;
      docs.forEach((doc) => {
        const word = doc.data();
        words.push({ ...word });
      });
      console.log({ words });
      // const wordsData = doc.data();
      // eslint-disable-next-line no-unused-vars
      //   for (const [id, word] of Object.entries(wordsData.list)) {
      //     words.push(word);
      //   }
      words.sort(function (a, b) {
        const aw = de_umlaut(a.word);
        const bw = de_umlaut(b.word);

        if (aw < bw) {
          return -1;
        }
        if (aw > bw) {
          return 1;
        }
        return 0;
      });
      console.log({ words });
      commit('setWords', words);
    });
  },
  updateWord({ commit, state }, { word }) {
    const updatedWords = state.words.map((w) => (w.id !== word.id ? w : word));
    commit('setWords', updatedWords);
  },
  async rebuildWordsfile() {
    const rebuildWordsfileFunc = functions.httpsCallable('updateConfigFile');
    return await rebuildWordsfileFunc();
  },
};
const mutations = {
  setWords(state, words) {
    state.json = JSON.stringify({ words });
    state.words = words;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
