const plans = [
  {
    name: "EiS Einzelabo",
    stripeId: "base-monthly",
    interval: "month",
    intervalName: "Monat",
    intervalAdjective: "monatlich",
    nickname: "basis",
    price: 4.95,
    deviceCount: 1,
    devicesText: "ein Gerät",
    features: [
      "mit einem Gerät nutzbar",
      "monatlich kündbar",
      "mehr als 150 Wörter",
    ],
  },
  {
    name: "EiS Familienabo",
    stripeId: "family-monthly",
    interval: "month",
    intervalName: "Monat",
    intervalAdjective: "monatlich",
    nickname: "family",
    price: 9.95,
    deviceCount: 5,
    devicesText: "bis zu fünf Geräte",
    features: [
      "mit bis zu fünf Geräten nutzbar",
      "monatlich kündbar",
      "mehr als 150 Wörter",
    ],
  },
  {
    name: "EiS Institution",
    stripeId: "institution-monthly",
    interval: "year",
    intervalName: "Monat",
    intervalAdjective: "monatlich",
    nickname: "institution",
    price: 99.95,
    deviceCount: 10,
    devicesText: "je Gerät",
    features: [
      "angegebener Antahl an Geräten nutzbar",
      "monatlich kündbar",
      "mehr als 150 Wörter",
    ],
  },
  //   {
  //     name: "EiS Einzelabo",
  //     stripeId: "base-yearly",
  //     interval: "year",
  //     intervalName: "Jahr",
  //     intervalAdjective: "jährlich",
  //     price: 49.95,
  //     devicesText: "ein Gerät",
  //     features: [
  //       "mit einem Gerät nutzbar",
  //       "jährlich kündbar",
  //       "mehr als 150 Wörter"
  //     ]
  //   },
  //   {
  //     name: "EiS Familienabo",
  //     stripeId: "family-yearly",
  //     intervalName: "Jahr",
  //     intervalAdjective: "jährlich",
  //     interval: "year",
  //     price: 99.95,
  //     devicesText: "bis zu fünf Geräte",
  //     features: [
  //       "mit bis zu fünf Geräten nutzbar",
  //       "jährlich kündbar",
  //       "mehr als 150 Wörter"
  //     ]
  //   }
];
export default plans;
