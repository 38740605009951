/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */
/* eslint no-unused-vars: ["error", { allow: ["log","warn", "error"] }] */
import { db, Timestamp } from '@/plugins/firebase';
import voucher_codes from 'voucher-code-generator';
import moment from 'moment';

const state = {
  error: '',
  message: '',
  campaigns: [],
  campaign: {},
  lastCampaignUpdate: null,
};
const getters = {
  getCampaigns: (state) => state.campaigns,
  getCampaign: (state) => state.campaign,
  getCampaignUpdated: (state) => state.lastCampaignUpdate,
  getError: (state) => state.error,
};
const actions = {
  async fetchCampaign({ commit, state }, { id, force }) {
    if (state.campaign && state.campaign.id === id && force !== true) {
      console.log('cached', { campaign: state.campaign });
      return;
    }
    let campaignRef = await db.collection('campaigns').doc(id).get();
    let campaign = campaignRef.data();

    let vouchers = [];
    let vouchersRef = await db
      .collection('voucher_codes')
      .where('campaignId', '==', id)
      .get();

    let voucherMetrics = {
      total: 0,
      redeemed: 0,
    };

    vouchersRef.forEach((doc) => {
      const voucher = doc.data();
      voucher['id'] = doc.id;
      vouchers.push(voucher);
      if (voucher.redeemed) {
        voucherMetrics.redeemed++;
      }
    });

    voucherMetrics.total = vouchers.length;

    campaign['vouchers'] = vouchers;
    campaign['vouchersMetrics'] = voucherMetrics;

    console.log({ campaign });
    commit('setCampaign', campaign);
  },

  async loadCampaigns({ commit }) {
    const collection = db.collection('campaigns');
    collection.get().then((querySnapshot) => {
      const campaigns = [];
      querySnapshot.forEach((doc) => {
        const campaign = doc.data();
        console.log(campaign);
        campaign['id'] = doc.id;
        // campaign['campaignStart'] = moment(
        //   campaign.campaignStart.toDate()
        // ).format('DD.MM.YYYY');
        campaign['campaignStart'] = campaign.campaignStart.toDate();

        // campaign['campaignEnd'] = moment(campaign.campaignEnd.toDate()).format(
        //   'DD.MM.YYYY'
        // );
        campaign['campaignEnd'] = campaign.campaignEnd.toDate();
        campaigns.push(campaign);
      });
      commit('setCampaigns', campaigns);
    });
  },

  async create({ commit, dispatch }, campaign) {
    let campaignStart = campaign.campaignStart;
    let campaignEnd = campaign.campaignEnd;
    let voucherCodeLength = campaign.voucherCodeLength;
    let voucherCodePrefix = campaign.voucherCodePrefix;

    let codes = voucher_codes.generate({
      length: voucherCodeLength,
      count: campaign.voucherCount,
      prefix: voucherCodePrefix,
      charset: '0123456789ABCDEFGHIJKLMNPQRSTUVWXYZ',
    });

    campaign['codes'] = codes;

    if (campaignStart != null) {
      campaignStart = moment(campaignStart, 'DD.MM.YYYY').toDate();
      campaignStart = Timestamp.fromDate(campaignStart);
    } else {
      campaignStart = Timestamp.now();
    }

    campaign['campaignStart'] = campaignStart;

    if (campaignEnd != null) {
      campaignEnd = moment(campaignEnd, 'DD.MM.YYYY').toDate();
      campaignEnd = Timestamp.fromDate(campaignEnd);
    } else {
      campaignEnd = Timestamp.now();
    }

    campaign['campaignEnd'] = campaignEnd;

    let campaignRef = await db.collection('campaigns').add(campaign);

    let batch = db.batch();
    for (let index = 0; index < codes.length; index++) {
      const code = codes[index];

      let voucher = {
        code,
        campaignId: campaignRef.id,
        campaignName: campaign.name,
        campaignEnd: campaign.campaignEnd,
        voucherCodeLength: campaign.voucherCodeLength,
        handedOut: false,
        handedOutAt: null,
        redeemed: false,
        redeemedAt: null,
        redeemedBy: null,
        validUntil: campaignEnd,
        message: campaign.message,
        type: campaign.type,
        value: campaign.value,
        deviceCount: campaign.deviceCount,
      };

      let voucherRef = db.collection('voucher_codes').doc(code);
      batch.set(voucherRef, voucher);

      // voucherPromises.push(
      //     db.collection('voucher_codes').doc(code).set(voucher)
      //     );
    }
    await batch.commit();

    console.log(campaign);
    console.log(codes);
    dispatch('loadCampaigns');
    commit('setMessage', 'Kampagne wurde erstellt.');
  },

  async update({ commit, dispatch }, campaign) {
    let campaignStart = campaign.campaignStart;
    let campaignEnd = campaign.campaignEnd;

    if (campaignStart != null) {
      // if campaignStart is not instanceof Timestamp
      // convert to Timestamp
      if (!(campaignStart instanceof Timestamp)) {
        campaignStart = moment(campaignStart, 'DD.MM.YYYY').toDate();
        campaignStart = Timestamp.fromDate(campaignStart);
      }
    } else {
      campaignStart = Timestamp.now();
    }

    campaign['campaignStart'] = campaignStart;

    if (campaignEnd != null) {
      if (!(campaignEnd instanceof Timestamp)) {
        campaignEnd = moment(campaignEnd, 'DD.MM.YYYY').toDate();
        campaignEnd = Timestamp.fromDate(campaignEnd);
      }
    } else {
      campaignEnd = Timestamp.now();
    }

    campaign['campaignEnd'] = campaignEnd;

    console.log(campaign);
    let campaignRef = await db.collection('campaigns').doc(campaign.id);
    await campaignRef.set(campaign, { merge: true });

    let voucherCodes = await db
      .collection('voucher_codes')
      .where('campaignId', '==', campaign.id)
      .get();
    let batch = db.batch();
    voucherCodes.forEach((doc) => {
      let voucher = doc.data();
      voucher['campaignEnd'] = campaign.campaignEnd;
      voucher['validUntil'] = campaign.campaignEnd;
      voucher['message'] = campaign.message;
      voucher['type'] = campaign.type;
      // parse campaign value to in
      voucher['value'] = parseInt(campaign.value);
      voucher['deviceCount'] = campaign.deviceCount;
      voucher['campaignName'] = campaign.name;

      let voucherRef = db.collection('voucher_codes').doc(doc.id);
      batch.set(voucherRef, voucher, { merge: true });
    });
    await batch.commit();
    dispatch('loadCampaigns');
    commit('setMessage', 'Kampagne wurde aktualisiert.');
  },

  async delete({ commit, dispatch }, campaign) {
    console.log(campaign);

    db.collection('voucher_codes')
      .where('campaignId', '==', campaign.id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          db.collection('voucher_codes').doc(doc.id).delete();
        });
      });

    await db.collection('campaigns').doc(campaign.id).delete();
    dispatch('loadCampaigns');
    commit('setMessage', 'Kampagne gelöscht.');
  },

  async toggleHandedOut({ commit, state }, {voucherId, handedOut}) {
    let voucherRef = db.collection('voucher_codes').doc(voucherId);
    const update = {handedOut};
    if (handedOut) {
        update.handedOutAt = Timestamp.now();
    }

    await voucherRef.set(update, {merge: true});
    const updatedCampaign = JSON.parse ( JSON.stringify ( state.campaign) );
    console.log(updatedCampaign);
    updatedCampaign.vouchers = updatedCampaign.vouchers.map((voucher) => {
        if (voucher.id === voucherId) {
            voucher.handedOut = update.handedOut;
            voucher.handedOutAt = update.handedOutAt;

        }
        return voucher;
    });
    console.log(updatedCampaign);
    commit('setCampaign', updatedCampaign);
}


};
const mutations = {
  setError(state, error) {
    state.error = error;
  },
  setMessage(state, message) {
    state.message = message;
  },
  setCurrent(state, release) {
    state.release = release;
  },
  setCampaigns(state, campaigns) {
    state.campaigns = campaigns;
  },
  setCampaign(state, campaign) {
    state.campaign = campaign;
    state.lastCampaignUpdate = new Date();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
