import Vue from "vue";
import { storage } from "@/plugins/firebase";

/**
 * get download url from firebase path and insert it into href element
 *
 * usage:
 * <a v-add-firebase-storage-src="path" src="dummy" download />
 */
Vue.directive("firebase-storage-src", {
  bind(el, binding) {
    if (binding.value === "") {
      return;
    }
    storage
      .ref()
      .child(binding.value)
      .getDownloadURL()
      .then((url) => {
        el.setAttribute("src", url);
      });
  },
});
