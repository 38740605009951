/* eslint no-unused-vars: ["error", { allow: ["log","warn", "error"] }] */
/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */

const state = {
  breadcrump: [],
  debug: false,
  title: '',
};
const getters = {
  breadcrump: (state) => state.breadcrump,
  debug: (state) => state.debug,
  title: (state) => state.title,
};
const actions = {
  async setBreadcrump({ commit }, { breadcrump }) {
    commit('setBreadcrump', breadcrump);
  },
  async setDebug({ commit }, { debug }) {
    commit('setDebug', debug);
  },
  async setTitle({ commit }, { title }) {
    commit('setTitle', title);
  },
};
const mutations = {
  setBreadcrump(state, breadcrump) {
    state.debug = breadcrump;
  },
  setDebug(state, breadcrump) {
    state.debug = breadcrump;
  },
  setTitle(state, title) {
    state.title = title;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
