/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */
/* eslint no-unused-vars: ["error", { allow: ["log","warn", "error"] }] */
import { db } from '@/plugins/firebase';
import { de_umlaut } from '../../plugins/helpers';

const state = {
  json: '',
  error: '',
  dictionaries: [],
};
const getters = {
  getList: (state) => state.dictionaries,
  getError: (state) => state.error,
};
const actions = {
  async listen({ commit, getters }) {
    if (getters.getList.length > 0) {
      return;
    }

    const docRef = await db.collection('config').doc('dictionaries');
    docRef.onSnapshot((doc) => {
      const docData = doc.data()['list'];

      let dictionaries = [];
      for (const key in docData) {
        if (Object.hasOwnProperty.call(docData, key)) {
          const dictionary = docData[key];
          dictionary['id'] = key;
          dictionaries.push(dictionary);
        }
      }

      dictionaries.sort(function (a, b) {
        const aw = de_umlaut(a.name);
        const bw = de_umlaut(b.name);

        if (aw < bw) {
          return -1;
        }
        if (aw > bw) {
          return 1;
        }
        return 0;
      });

      commit('setDictionaries', dictionaries);
    });
  },

  async create({ commit, getters }, payload) {
    commit('setError', '');
    const { dictionary } = payload;

    if (
      getters.getList.findIndex((dict) => dict.name === dictionary['name']) >= 0
    ) {
      commit(
        'setError',
        'Wörterbuch mit Namen "' + dictionary['name'] + '" existiert bereits.'
      );
      return;
    }

    if (dictionary['name'] === '') {
      commit('setError', 'Bitte einen Namen für das Wörterbuch angeben.');
      return;
    }
    // category['sort_name'] = de_umlaut(category['name']);

    dictionary['locked'] = dictionary['locked'] === true ? true : false;
    await db.collection('word_dictionaries').add(dictionary);
    const updated_dictionaries = getters.getList.slice();
    dictionary['created'] = true;
    updated_dictionaries.push(dictionary);
    commit('setDictionaries', updated_dictionaries);
  },
  async update({ commit, getters }, payload) {
    commit('setError', '');
    const { dictionary } = payload;

    if (
      getters.getList.findIndex(
        (dict) =>
          dict.name === dictionary['name'] && dict.id !== dictionary['id']
      ) >= 0
    ) {
      commit(
        'setError',
        'Wörterbuch mit Namen "' + dictionary['name'] + '" existiert bereits.'
      );
      return;
    }

    if (dictionary['name'] === '') {
      commit('setError', 'Bitte einen Namen für das Wörterbuch angeben.');
      return;
    }

    await db
      .collection('word_dictionaries')
      .doc(dictionary.id)
      .set(dictionary, { merge: true });

    const updated_dictionaries = getters.getList
      .slice()
      .filter((dict) => dict.id !== dictionary.id);

    updated_dictionaries.push(dictionary);

    commit('setDictionaries', updated_dictionaries);
  },
  async delete({ commit, getters }, payload) {
    const { dictionary } = payload;
    if (dictionary['locked'] === true) {
      commit('setError', 'Wörterbuch gesperrt');
      return;
    }
    // await db.collection('word_dictionaries').doc(dictionary.id).delete();

    const updated_dictionaries = getters.getList
      .slice()
      .filter((dict) => dict.id !== dictionary.id);

    commit('setDictionaries', updated_dictionaries);
  },
};
const mutations = {
  setError(state, error) {
    state.error = error;
  },
  setDictionaries(state, dictionaries) {
    state.dictionaries = dictionaries;
  },
  setConfigDictionaries(state, configDictionaries) {
    const sortedDictionaries = configDictionaries.sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    state.configDictionaries = sortedDictionaries;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
