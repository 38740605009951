/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */
/* eslint no-unused-vars: ["error", { allow: ["log","warn", "error"] }] */
import { db, functions, FieldValue } from "@/plugins/firebase";

const state = {
  users: [],
  user: {},
  error: null,
  loading: false,
};

const getters = {
  get: (state) => state.user,
  error: (state) => state.error,
  loading: (state) => state.loading,
};

const actions = {
  async fetch({ commit }, { uid }) {
    commit("setLoading", true);
    const docRef = db.collection("users").doc(uid);
    commit("setError", null);
    try {
      const userDoc = await docRef.get();
      if (userDoc.exists) {
        const userData = getUserFromData(userDoc.data());
        userData.emailConfirmed = true;
        const confirmationRef = await db
          .collection("user_verifications")
          .where("uid", "==", uid)
          .get();
        if (!confirmationRef.empty) {
          const confirmationData = confirmationRef.docs[0].data();
          userData.emailConfirmed = false;
          userData.confirmationHash = confirmationData.hash;
        }
        commit("setUser", userData);
      } else {
        commit("setError", "Not found");
      }
    } catch (error) {
      commit("setError", error);
    }
    commit("setLoading", false);
  },
  async delete({ commit }, { uid }) {
    commit("setLoading", true);
    const deleteUserFunc = functions.httpsCallable("httpAdminDeleteUser");
    const result = await deleteUserFunc({ uid });
    console.log(result);
    commit("setLoading", false);
  },
  // eslint-disable-next-line no-empty-pattern
  async setDevices({}, { devices, uid }) {
    console.error("user/setDevices not implemented", { devices, uid });
  },
  async setUserType({ commit, dispatch }, { type, uid }) {
    commit("setLoading", true);
    const update = {
      type,
    };
    await dispatch("updateUser", { uid, update });
    commit("setLoading", false);
  },
  async setSettings({ commit, dispatch }, { settings, uid }) {
    commit("setLoading", true);
    const update = {
      settings,
    };
    await dispatch("updateUser", { uid, update });
    commit("setLoading", false);
  },
  async updateUser({ commit, dispatch }, { update, uid }) {
    const docRef = db.collection("users").doc(uid);
    await docRef.set(update, { merge: true });
    const updatedUser = { ...state.user, ...update };
    commit("setUser", updatedUser);
    await dispatch("fetch", { uid });
  },
  async cancelSubscription({ commit, dispatch }, { subscription, uid }) {
    commit("setLoading", true);
    const update = {
      current_subscription: FieldValue.delete(),
      subscription_history: FieldValue.arrayUnion(subscription),
    };

    const docRef = db.collection("users").doc(uid);
    await docRef.set(update, { merge: true });
    await dispatch("fetch", { uid: uid });
    commit("setLoading", false);
  },
  addCustomSubscription({ commit, state }, { subscription }) {
    commit("setLoading", true);
    let uid = state.user.uid;

    var docRef = db.collection("users").doc(uid);
    docRef.get().then(function (doc) {
      console.log(doc, subscription);
      commit("setLoading", false);
    });
  },
  async confirmEmail({ commit, dispatch }, { hash, uid }) {
    console.log("confirmEmail", hash, uid);
    commit("setLoading", true);
    try {
      const confirmUserFunc = functions.httpsCallable("httpAdminConfirmUser");
      const confirmResult = await confirmUserFunc({ hash, uid });
      console.log(confirmResult);
      await dispatch("fetch", { uid: uid });
    //   const update = { emailConfirmed: true };
    //   const updatedUser = { ...state.user, ...update };
    //   commit("setUser", updatedUser);
    } catch (error) {
      console.error(error);
    }
    commit("setLoading", false);
  },
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setUser(state, user) {
    state.user = user;
  },
  setError(state, error) {
    state.error = error;
  },
};

function getUserFromData(user) {
  if (user.devices != undefined) {
    try {
      user.devices = user.devices.sort(
        (a, b) => b["lastSeen"].toDate() - a["lastSeen"].toDate()
      );
    } catch (error) {
      console.error(error);
    }
  }
  return user;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
