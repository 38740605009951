/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */
/* eslint no-unused-vars: ["error", { allow: ["log","warn", "error"] }] */
// eslint-disable-next-line no-unused-vars
import { db, storage, firestore } from '@/plugins/firebase';
import defaultWord from '@/data/defaultWord';
import router from '@/plugins/router';
import { v4 as uuidv4 } from 'uuid';

const state = {
  json: '',
  word: {},
  initialWord: {},
  initialWordJson: '',
  isNew: false,
  saving: false,
  loading: false,
  //   dirty: false,
  error: '',
};
const getters = {
  json: (state) => state.json,
  word: (state) => state.word,
  isNew: (state) => state.isNew,
  saving: (state) => state.saving,
  loading: (state) => state.loading,
  dirty: (state) => state.json !== state.initialWordJson,
  error: (state) => state.error,
};
const actions = {
  // eslint-disable-next-line no-unused-vars
  async fetchWord({ commit, dispatch }, { id }) {
    // const word = {};
    commit('setLoading', true);
    const docRef = db.collection('words').doc(id);
    console.log('fetch', id);
    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          commit('setIsNew', false);
          const word = { id: doc.id, ...doc.data() };
          dispatch('words/updateWord', { word: word }, { root: true });
          commit('setInitialWord', word);
        } else {
          commit('setError', "'" + id + "' not found");
        }
      })
      .catch(function (error) {
        commit('setError', error);
        // console.log("Error getting document:", error);
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },
  createWord({ commit }) {
    const word = { ...defaultWord };
    commit('setIsNew', true);
    commit('setInitialWord', word);
    commit('setLoading', false);
  },
  updateWord({ commit }, { word }) {
    commit('setWord', word);
  },

  addWord({ commit, dispatch }, { word }) {
    commit('setSaving', true);
    word.created = firestore.FieldValue.serverTimestamp();
    db.collection('words')
      .add(word)
      .then(async (docRef) => {
        const id = docRef.id;
        await docRef.set({ id }, { merge: true });
        dispatch('fetchWord', { id });
        router.push('/word/' + id);
      })
      .catch((e) => {
        commit('setError', { e });
      })
      .finally(() => {
        commit('setSaving', false);
      });
  },
  saveWord({ commit, dispatch }, { id, word }) {
    commit('setSaving', true);
    const docRef = db.collection('words').doc(id);
    docRef
      .set(word, { merge: true })
      .then(() => {
        dispatch('fetchWord', { id });
      })
      .catch((e) => {
        commit('setError', { e });
      })
      .finally(() => {
        commit('setSaving', false);
      });
  },

  async deleteWord({ commit }, { word }) {
    commit('setSaving', true);
    // const symbolFilePath = word.symbol.filePath;
    // const storageRef = storage.ref();
    // const symbolFileRef = storageRef.child(symbolFilePath);
    // await symbolFileRef.delete();

    // if (word.audios) {
    //   for (const audio of word.audios) {
    //     let audioFileRef = storageRef.child(audio.filePath);
    //     await audioFileRef.delete();
    //   }
    // }
    // if (word.videos) {
    //   for (const video of word.videos) {
    //     let videoFileRef = storageRef.child(video.filePath);
    //     await videoFileRef.delete();
    //   }
    // }
    const docRef = db.collection('words').doc(word.id);
    await docRef.delete();
    router.push('/words/');
    commit('setSaving', false);
  },
  async addSymbol({ commit, dispatch }, { word, file }) {
    commit('setSaving', true);
    const fileid = uuidv4();
    const wordid = word.id;
    const storageRef = storage.ref();
    const fileExtension = file.name.split('.').pop();
    const fileDestination = `files/${wordid}/${fileid}.${fileExtension}`;
    const fileRef = storageRef.child(fileDestination);
    await fileRef.put(file);
    const wordUpdate = {
      ...word,
      symbol: { id: fileid, filePath: fileDestination },
    };
    await dispatch('saveWord', { id: wordid, word: wordUpdate });
  },
  async deleteSymbol({ commit, dispatch }, { word }) {
    commit('setSaving', true);
    // const filePath = word.symbol.filePath;
    // const storageRef = storage.ref();
    // const fileRef = storageRef.child(filePath);
    // await fileRef.delete();
    const wordUpdate = {
      symbol: {
        filePath: firestore.FieldValue.delete(),
      },
    };
    await dispatch('saveWord', { id: word.id, word: wordUpdate });
  },
  async addAudio({ commit, dispatch }, { word, file, fileInfo }) {
    commit('setSaving', true);
    const fileid = uuidv4();
    const wordid = word.id;
    const storageRef = storage.ref();
    const fileExtension = file.name.split('.').pop();
    const fileDestination = `files/${wordid}/${fileid}.${fileExtension}`;
    const fileRef = storageRef.child(fileDestination);
    await fileRef.put(file);

    const audio = {
      id: fileid,
      filePath: fileDestination,
      mimeType: file.type,
      voice: fileInfo.voice,
    };

    let audios = [];
    if (word.audios.length > 0) {
      audios = word.audios;
    }
    audios.push(audio);
    const wordUpdate = { ...word, audios };
    await dispatch('saveWord', { id: wordid, word: wordUpdate });
  },
  async deleteAudio({ commit, dispatch }, { word, id }) {
    commit('setSaving', true);
    // let audio = word.audios[0];
    let audios = [];

    if (id !== '') {
      //   audio = word.audios.find((element) => (element.id = id));
      audios = word.audios.filter((element) => element.id != id);
    }

    // const storageRef = storage.ref();
    // const fileRef = storageRef.child(audio.filePath);
    // await fileRef.delete();
    const wordUpdate = {
      audios,
    };
    await dispatch('saveWord', { id: word.id, word: wordUpdate });
  },
  async addVideo({ commit, dispatch }, { word, file, fileInfo }) {
    console.log('savie video');
    commit('setSaving', true);
    const fileid = uuidv4();
    const wordid = word.id;
    const storageRef = storage.ref();
    const fileExtension = file.name.split('.').pop();
    const fileDestination = `files/${wordid}/${fileid}.${fileExtension}`;
    const fileRef = storageRef.child(fileDestination);
    await fileRef.put(file);

    const video = {
      id: fileid,
      filePath: fileDestination,
      mimeType: file.type,
      language: fileInfo.language,
    };

    let videos = [];
    if (word.videos.length > 0) {
      videos = word.videos;
    }
    videos.push(video);
    const wordUpdate = { ...word, videos };
    await dispatch('saveWord', { id: wordid, word: wordUpdate });
  },
  async deleteVideo({ commit, dispatch }, { word, id }) {
    commit('setSaving', true);
    // let video = word.videos[0];
    let videos = [];

    if (id !== '') {
      //   video = word.videos.find((element) => (element.id = id));
      videos = word.videos.filter((element) => element.id != id);
    }

    // const storageRef = storage.ref();
    // const fileRef = storageRef.child(video.filePath);
    // await fileRef.delete();
    const wordUpdate = {
      videos,
    };
    await dispatch('saveWord', { id: word.id, word: wordUpdate });
  },
  async uploadFile({ commit }, { file, filePath }) {
    commit('setSaving', true);
    const storageRef = storage.ref();
    const fileRef = storageRef.child(filePath);
    await fileRef.put(file);
  },
};

const mutations = {
  setWord(state, word) {
    state.word = { ...state.word, ...word };
    state.json = JSON.stringify(state.word);
    // state.dirty = state.json !== state.initialWordJson;
    // console.log({ dirty: state.dirty });
  },
  setInitialWord(state, word) {
    const json = JSON.stringify(word);
    state.word = word;
    state.json = json;
    state.initialWord = word;
    state.initialWordJson = json;
    // state.dirty = state.json !== state.initialWordJson;
  },
  setSaving(state, saving) {
    state.saving = saving;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setIsNew(state, isNew) {
    state.isNew = isNew;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
