/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */
/* eslint no-unused-vars: ["error", { allow: ["log","warn", "error"] }] */
import { db, storage } from '@/plugins/firebase';
import { v4 as uuidv4 } from 'uuid';
import { de_umlaut_strict, delay } from '../../plugins/helpers';

const state = {
  error: null,
  saving: false,
  syncingWordFiles: false,
  currentWord: '',
  currentFile: '',
  totalWords: 0,
  count: 0,
};

const getters = {
  error: (state) => state.error,
  saving: (state) => state.saving,
  syncingWordFiles: (state) => state.syncingWordFiles,
  totalWords: (state) => state.totalWords,
  count: (state) => state.count,
  currentWord: (state) => state.currentWord,
  currentFile: (state) => state.currentFile,
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  async updateCategoriesOnWords({ commit, dispatch }) {
    const newDictsSourceDoc = await db
      .collection('config')
      .doc('dictionaries')
      .get();
    const newCatsSourceDoc = await db
      .collection('config')
      .doc('categories')
      .get();
    const wordsSourceDoc = await db.collection('config').doc('words').get();
    const newDictionaries = newDictsSourceDoc.data()['list'];
    const newCategories = newCatsSourceDoc.data()['list'];
    const words = wordsSourceDoc.data()['list'];
    const newCategoriesList = [];
    const notfound = {};
    const notfound_dict = {};
    let counter = 0;
    for (const key in newCategories) {
      if (Object.hasOwnProperty.call(newCategories, key)) {
        const cat = newCategories[key];
        cat['id'] = key;
        newCategoriesList.push(cat);
      }
    }
    const newDictionariesList = [];
    for (const key in newDictionaries) {
      if (Object.hasOwnProperty.call(newDictionaries, key)) {
        const dict = newDictionaries[key];
        dict['id'] = key;
        newDictionariesList.push(dict);
      }
    }

    for (const wordId in words) {
      counter++;
      if (counter > 5) {
        // continue;
      }
      const word = words[wordId];
      let wordUpdate = word;
      // console.log(word['categories']);
      if (!word.categories && !word.dictionaries) {
        continue;
      }

      if (word.categories_ids) {
        console.log('cut ' + word.word);
        continue;
      }

      const dictionaries_ids = [];
      if (word.dictionaries) {
        word.dictionaries.forEach(function (dictionaryName) {
          if (dictionaryName === '') {
            return;
          }

          console.log(dictionaryName);
          let newdict = newDictionariesList.find(
            (dict) => dict.id_before === dictionaryName
          );
          // console.log(word.word + ': ' + categoryName, newcat);
          if (!newdict) {
            notfound_dict[dictionaryName] = notfound_dict[dictionaryName]
              ? notfound_dict[dictionaryName] + 1
              : 1;
            console.log(
              dictionaryName +
                ' - ' +
                de_umlaut_strict(dictionaryName) +
                ' not found: ',
              dictionaryName
            );
            return;
          }

          if (dictionaries_ids.indexOf(newdict.id) < 0) {
            dictionaries_ids.push(newdict.id);
          }
        });

        wordUpdate = { ...wordUpdate, dictionaries_ids };
      }

      const categories_ids = [];

      if (word.categories) {
        word.categories.forEach(function (categoryName) {
          if (categoryName === '') {
            return;
          }
          if (categoryName === 'farben') {
            categoryName = 'farbenundmuster';
          }
          if (categoryName === 'essen') {
            categoryName = 'essenundtrinken';
          }
          if (categoryName === 'adjektive') {
            categoryName = 'adjektive (wie-worter)';
          }
          if (categoryName === 'verben') {
            categoryName = 'verben (tu-worter)';
          }
          if (categoryName === 'kleineworte') {
            categoryName = 'kleineworter';
          }
          if (categoryName === 'zeitwoerter') {
            categoryName = 'zeitworter';
          }
          let newcat = newCategoriesList.find(
            (cat) =>
              cat.sort_name === categoryName ||
              de_umlaut_strict(cat.sort_name) === de_umlaut_strict(categoryName)
          );
          // console.log(word.word + ': ' + categoryName, newcat);
          if (!newcat) {
            notfound[categoryName] = notfound[categoryName]
              ? notfound[categoryName] + 1
              : 1;
            // console.log(categoryName + ' - ' + de_umlaut_strict(categoryName) + ' not found: ', newcat);
            return;
          }

          if (categories_ids.indexOf(newcat.id) < 0) {
            categories_ids.push(newcat.id);
          }
        });
        wordUpdate = { ...wordUpdate, categories_ids };
      }
      console.log(wordUpdate);
      await delay(100);
      // await dispatch('word/saveWord', { id: wordId, word: wordUpdate }, {root:true});
    }
    console.log(notfound_dict);
  },

  async syncWordsConfig({ commit }) {
    commit('setSaving', true);
    const destDoc = db.collection('config').doc('words');
    await db
      .collection('words')
      .get()

      .then((snapshot) => {
        if (snapshot.empty) {
          return;
        }
        let counter = 0;
        const wordList = {};
        snapshot.forEach((doc) => {
          counter++;
          const word = doc.data();
          const id = doc.id;
          //   console.log('syncings', { word });
          wordList[id] = word;

          commit('setSaving', false);
        });

        const updated = destDoc.set({ list: wordList }, { merge: true });
        console.log(counter + ' updated:', updated);
      });
  },
  async syncCategoriesConfig({ commit }) {
    commit('setSaving', true);
    const destDoc = db.collection('config').doc('categories');
    await db
      .collection('word_categories')
      .get()

      .then((snapshot) => {
        if (snapshot.empty) {
          return;
        }

        const wordList = {};
        snapshot.forEach((doc) => {
          const word = doc.data();
          const id = doc.id;
          console.log('syncings', { word });
          wordList[id] = word;

          commit('setSaving', false);
        });

        const updated = destDoc.set({ list: wordList }, { merge: true });
        console.log('updated:', updated);
      });
  },
  async syncWords({ commit }) {
    commit('setSaving', true);
    console.log('syncing');
    const destCollection = db.collection('words');
    await db
      .collection('xwords')
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return;
        }

        snapshot.forEach((doc) => {
          const word = doc.data();
          console.log('syncings', { word });
          //word['id'] = doc.id;
          //   const destRef = destCollection.doc();

          if (word['id']) {
            delete word['id'];
          }

          const updated = destCollection.add(word);
          console.log('updated:', updated);
        });
      })
      .catch((err) => {
        this.error = err;
        console.log('Error syncWords', { err });
      });
    commit('setSaving', false);
    commit('setError', null);
  },
  async syncCategories({ commit }) {
    commit('setSaving', true);
    console.log('syncing');
    const destCollection = db.collection('word_categories');
    await db
      .collection('categories')
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return;
        }

        snapshot.forEach((doc) => {
          const cat = doc.data();
          console.log('syncings', { cat });
          //word['id'] = doc.id;
          //   const destRef = destCollection.doc();

          if (cat['id']) {
            delete cat['id'];
          }
          cat['show_in_app'] = false;
          cat['sort_name'] = cat['name']
            .toLowerCase()
            .replace(/ä/g, 'a')
            .replace(/ü/g, 'u')
            .replace(/ö/g, 'o');

          const updated = destCollection.add(cat);
          console.log('updated:', updated);
        });
      })
      .catch((err) => {
        this.error = err;
        console.log('Error syncWords', { err });
      });
    commit('setSaving', false);
    commit('setError', null);
  },
  async syncWordFiles({ commit, dispatch }) {
    commit('setSyncingWordFiles', true);

    const sourceDoc = await db.collection('config').doc('words').get();
    const sourceData = sourceDoc.data();
    const sourceWords = sourceData['list'];
    let total = Object.keys(sourceWords).length;
    commit('setTotalWords', total);

    let count = 1;
    for (const wordId in sourceWords) {
      const word = sourceWords[wordId];
      if (count > 100) {
        continue;
      }

      commit('setCurrentWord', word.word);
      commit('setCount', count);
      console.log(wordId, word);
      let wordUpdate = word;
      let wordUpdated = false;
      if (word.symbol && word.symbol.filePath) {
        const origPath = word.symbol.filePath;
        if (origPath.substring(0, 5) === 'words') {
          // if (word.id === '2MD7VNTCTnWuFJLq0ja3') {

          commit('setCurrentFile', origPath);
          console.log('move symbol ' + word.word);
          const fileId = uuidv4();
          const fileExtension = origPath.split('.').pop();
          const fileDestination = `files/${wordId}/${fileId}.${fileExtension}`;
          console.log('copy start');
          await copyFirebaseFile(origPath, fileDestination, 'image/png');
          console.log('copy done', word.id);
          count++;
          wordUpdate = {
            ...wordUpdate,
            symbol: { id: fileId, filePath: fileDestination },
          };
          wordUpdated = true;
          await dispatch(
            'word/saveWord',
            { id: wordId, word: wordUpdate },
            { root: true }
          );
        }
      }

      if (word.audios && word.audios.length > 0) {
        for (let index = 0; index < word.audios.length; index++) {
          const audioElement = word.audios[index];
          const origPath = audioElement.filePath;
          if (origPath.substring(0, 5) === 'words') {
            commit('setCurrentFile', origPath);
            console.log(word.id + ' move audio ' + word.word);
            const fileId = uuidv4();
            const fileExtension = origPath.split('.').pop();
            const fileDestination = `files/${wordId}/${fileId}.${fileExtension}`;
            await copyFirebaseFile(origPath, fileDestination, 'audio/mp3');
            wordUpdate = {
              ...wordUpdate,
              audios: [
                { ...audioElement, id: fileId, filePath: fileDestination },
              ],
            };
            wordUpdated = true;
          }
        }
      }

      if (word.videos && word.videos.length > 0) {
        for (let index = 0; index < word.videos.length; index++) {
          const videoElement = word.videos[index];
          const origPath = videoElement.filePath;
          if (origPath.substring(0, 5) === 'words') {
            commit('setCurrentFile', origPath);
            console.log(word.id + ' move video ' + word.word);
            const fileId = uuidv4();
            const fileExtension = origPath.split('.').pop();
            const fileDestination = `files/${wordId}/${fileId}.${fileExtension}`;
            await copyFirebaseFile(origPath, fileDestination, 'video/mp4');
            if (
              videoElement['language'] === 'DBG' ||
              videoElement['language'] === 'DGB'
            ) {
              videoElement['language'] = 'DGS';
            }
            wordUpdate = {
              ...wordUpdate,
              videos: [
                { ...videoElement, id: fileId, filePath: fileDestination },
              ],
            };
            wordUpdated = true;
          }
        }
      }
      if (wordUpdated === true) {
        count++;
        await dispatch(
          'word/saveWord',
          { id: wordId, word: wordUpdate },
          { root: true }
        );
      }
    }
    console.log(sourceData);

    setTimeout(() => commit('setSyncingWordFiles', false), 2000);
  },
};

const mutations = {
  setSaving(state, saving) {
    state.saving = saving;
  },
  setError(state, error) {
    state.error = error;
  },
  setSyncingWordFiles(state, syncingWordFiles) {
    state.syncingWordFiles = syncingWordFiles;
  },
  setTotalWords(state, totalWords) {
    state.totalWords = totalWords;
  },
  setCount(state, count) {
    state.count = count;
  },
  setCurrentWord(state, currentWord) {
    state.currentWord = currentWord;
  },
  setCurrentFile(state, currentFile) {
    state.currentFile = currentFile;
  },
};

async function copyFirebaseFile(currentPath, destinationPath, type) {
  let oldRef = storage.ref().child(currentPath);

  let downloadUrl = await oldRef.getDownloadURL();
  let htmlReturn = await fetch(downloadUrl);
  let fileArray = new Uint8Array();
  const reader = htmlReturn.body.getReader();

  const file = await reader
    .read()
    .then(function appendStreamChunk({ done, value }) {
      //If the reader doesn't return "done = true" append the chunk that was returned to us
      // rinse and repeat until it is done.
      if (value) {
        fileArray = mergeTypedArrays(fileArray, value);
      }
      if (done) {
        console.log(fileArray);
        return fileArray;
      } else {
        // "Readout not complete, reading next chunk"
        return reader.read().then(appendStreamChunk);
      }
    });

  const myNewFile = new File([file], file.name, { type: type });
  //Write the file to the new storage place
  let status = await storage.ref().child(destinationPath).put(myNewFile);
  //Remove the old reference
  // oldRef.delete()
  console.log('upload done');
  return status;
}

function mergeTypedArrays(a, b) {
  // Checks for truthy values on both arrays
  if (!a && !b) throw 'Please specify valid arguments for parameters a and b.';

  // Checks for truthy values or empty arrays on each argument
  // to avoid the unnecessary construction of a new array and
  // the type comparison
  if (!b || b.length === 0) return a;
  if (!a || a.length === 0) return b;

  // Make sure that both typed arrays are of the same type
  if (Object.prototype.toString.call(a) !== Object.prototype.toString.call(b))
    throw 'The types of the two arguments passed for parameters a and b do not match.';

  var c = new a.constructor(a.length + b.length);
  c.set(a);
  c.set(b, a.length);

  return c;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
