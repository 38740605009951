export function de_umlaut(value) {
    value = value.toLowerCase();
    value = value.replace(/ä/g, 'ae');
    value = value.replace(/ö/g, 'oe');
    value = value.replace(/ü/g, 'ue');
    value = value.replace(/ß/g, 'ss');
    return value;
}

export function de_umlaut_strict(value) {
    value = value.toLowerCase();
    value = value.replace(/ä/g, 'a');
    value = value.replace(/ö/g, 'o');
    value = value.replace(/ü/g, 'u');
    value = value.replace(/ß/g, 's');
    value = value.replace(/ /g, '');
    value = value.replace(/\//g, '');
    return value;
}

export const delay = ms => new Promise(res => setTimeout(res, ms));